<template>
  <div id="personas-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/personas" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5">Persona speichern</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->

    <div class="card mb-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-sm-3">
            <div class="avatar-wrapper">
              <img :src="newPersona.logo" alt="Profilbild" class="img-fluid avatar">
            </div>
            <input id="avatar-url" v-model="newPersona.logo" class="form-control mb-3" placeholder="URL einfügen" type="text">
            <p class="text-center small text-muted">oder:</p>
            <FileUpload @filesSaved="filesSaved($event)" :multiple="false"></FileUpload>
          </div>
          <div class="col-xs-6 col-sm">
            <label>Wie soll deine Persona heißen?</label>
            <input type="text" v-model="newPersona.name" class="form-control">

            <label class="mt-3">Alter:</label>
            <input type="text" v-model="newPersona.age" class="form-control">

            <label>Wie würdest du die Persona zusammenfassen?</label>
            <input type="text" v-model="newPersona.title" class="form-control">

            <label class="mt-3">Hat deine Persona bereits ein Motto?</label>
            <textarea v-model="newPersona.motto" class="form-control"></textarea>
          </div>
          <div class="col-3">
            <LimbicMap :types="[newPersona.limbic]" :small="true"></LimbicMap>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4 border-right">
            <h3 class="h4">Haupt-Jobs</h3>
            <ul class="fa-ul">
              <li v-for="(job, index) in connectedJobs" :key="job.id">
                <span class="fa-li"><i class="fad fa-long-arrow-right text-primary"></i></span>
                <router-link :to="'/jobs/' + job.id">{{ job.title }}</router-link>
                <i @click="remove('jobIds', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li v-for="(job, index) in newPersona.customJobs" :key="index">
                <span class="fa-li"><i class="fad fa-long-arrow-right text-primary"></i></span> {{ job }}
                <i @click="remove('customJobs', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add"><span class="fa-li"><i class="fad fa-long-arrow-right text-primary"></i></span>
                <label>Erstellte Jobs verknüpfen:</label>
                <select v-if="jobs.length > 0" @change="addJob($event)" class="form-control form-control-sm">
                  <option value="">Choose Job...</option>
                  <option v-for="job in jobs" :value="job.id" :key="job.id">{{ job.title }}</option>
                </select>
                <label class="mt-2">Neuen Job erstellen:</label>
                <input type="text" @change="add('customJobs', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-4 border-right">
            <h3 class="h4">Haupt-Vorteile</h3>
            <ul class="fa-ul">
              <li v-for="(gain, index) in newPersona.gains" :key="index">
                <span class="fa-li"><i class="fad fa-plus text-success"></i></span> {{ gain }}
                <i @click="remove('gains', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad pt-2 fa-plus text-success"></i></span>
                <label>Erstellte Gains verknüpfen:</label>
                <select v-if="gains.length > 0" @change="add('gains', $event)" class="form-control form-control-sm">
                  <option value="">Choose Gain...</option>
                  <option v-for="(gain, index) in gains" :value="gain" :key="index">{{ gain }}</option>
                </select>
                <label class="mt-2">Neuen Gain erstellen:</label>
                <input type="text" @change="add('gains', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-4">
            <h3 class="h4">Haupt-Bedenken</h3>
            <ul class="fa-ul">
              <li v-for="(pain, index) in newPersona.pains" :key="index">
                <span class="fa-li"><i class="fad fa-minus text-danger"></i></span> {{ pain }}
                <i @click="remove('pains', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad pt-2 fa-minus text-danger"></i></span>
                <label>Erstellte Pains verknüpfen:</label>
                <select v-if="pains.length > 0" @change="add('pains', $event)" class="form-control form-control-sm">
                  <option value="">Choose Pain...</option>
                  <option v-for="(pain, index) in pains" :value="pain" :key="index">{{ pain }}</option>
                </select>
                <label class="mt-2">Neuen Pain erstellen:</label>
                <input type="text" @change="add('pains', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-6 border-right">
            <h3 class="h4">Kaufverhalten</h3>
            <ul class="fa-ul">
              <li v-for="(behaviour, index) in newPersona.buyingBehaviour" :key="index">
                <span class="fa-li"><i class="fad fa-money-bill text-success"></i></span> {{ behaviour }}
                <i @click="remove('buyingBehaviour', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad pt-2 fa-money-bill text-success"></i></span>
                <label>Neues Kaufverhalten erstellen:</label>
                <input type="text" @change="add('buyingBehaviour', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <h3 class="h4">Haupt-Produkte</h3>
            <ul class="fa-ul">
              <li v-for="(product, index) in newPersona.products" :key="index">
                <span class="fa-li"><i class="fad fa-paperclip text-info"></i></span> {{ product }}
                <i @click="remove('products', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad pt-2 fa-paperclip text-info"></i></span>
                <label>Erstellte Produkte verknüpfen:</label>
                <select v-if="products.length > 0" @change="add('products', $event)" class="form-control form-control-sm">
                  <option value="">Choose Product...</option>
                  <option v-for="(product, index) in products" :value="product" :key="index">{{ product }}</option>
                </select>
                <label class="mt-2">Neues Produkt erstellen:</label>
                <input type="text" @change="add('products', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-6 border-right">
            <h3>Relevante Unique Value Propositions</h3>
            <ul class="fa-ul">
              <li v-for="(valueProp, index) in newPersona.valueProps" :key="index">
                <span class="fa-li"><i class="fad fa-sparkles text-primary"></i></span>
                {{ valueProp }}
                <i @click="remove('valueProps', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad fa-sparkles text-primary"></i></span>
                <label>Erstellte UVPs verknüpfen:</label>
                <select v-if="valueProps.length > 0" @change="add('valueProps', $event)" class="form-control form-control-sm">
                  <option value="">Choose UVPs...</option>
                  <option v-for="(valueProp, index) in valueProps" :value="valueProp" :key="index">{{ valueProp }}</option>
                </select>
                <label class="mt-2">Neue UVP erstellen:</label>
                <input type="text" @change="add('valueProps', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <h3>Mögliche Eigenarten</h3>
            <ul class="fa-ul">
              <li v-for="(consideration, index) in newPersona.consideration" :key="index">
                <span class="fa-li"><i class="fad fa-question-circle"></i></span>
                {{ consideration }}
                <i @click="remove('consideration', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <span class="fa-li"><i class="fad fa-question-circle"></i></span>
                <label>Neue Eigenart erstellen:</label>
                <input type="text" @change="add('consideration', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
        </div>
        <hr>
      </div>
      <div class="card-footer">
        <h3>Persönliche Geschichte</h3>
        <textarea v-model="newPersona.story" class="form-control"></textarea>
        <hr>
        <div class="row">
          <div class="col-sm-4 border-right">
            <h3 class="h4">Familie</h3>
            <ul>
              <li v-for="(family, index) in newPersona.family" :key="index">
                {{ family }}
                <i @click="remove('family', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <input type="text" @change="add('family', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
            <h3 class="h4">Lifestyle</h3>
            <ul>
              <li v-for="(lifestyle, index) in newPersona.lifestyle" :key="index">
                {{ lifestyle }}
                <i @click="remove('lifestyle', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <input type="text" @change="add('lifestyle', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-4 border-right">
            <h3 class="h4">Hobbies</h3>
            <ul>
              <li v-for="(hobby, index) in newPersona.hobbys" :key="index">
                {{ hobby }}
                <i @click="remove('hobbys', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <input type="text" @change="add('hobbys', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
          <div class="col-sm-4">
            <h3 class="h4">Beruf</h3>
            <ul>
              <li v-for="(occupation, index) in newPersona.occupation" :key="index">
                {{ occupation }}
                <i @click="remove('occupation', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-item-add">
                <input type="text" @change="add('occupation', $event)" class="form-control form-control-sm" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <h2>Charakter-Karte</h2>
    <div class="row">
      <div class="col-sm-9 order-sm-2">
        <LimbicMap :add-type="newPersona.limbic" :types="[]" :small="false"></LimbicMap>
      </div>
      <div class="col-sm-3 order-sm-1 border-sm-right mt-4">
        <p>
          Beschreibe deine Persona mit Werten auf der Charakter-Karte.
        </p>
        <ul class="fa-ul">
          <li v-for="(tag, index) in newPersona.limbic.tags" :key="index">
            <span class="fa-li"><i class="fad fa-star text-warning"></i></span> {{ tag.name }}
            <i @click="removeValue(index)" class="fal fa-minus-circle text-danger float-right"></i>
          </li>
          <!--<li class="list-item-add">
            <span class="fa-li"><i class="fad pt-2 fa-star text-warning"></i></span>
            <label>Connect Limbic Values:</label>
            <select v-if="limbicTags.length > 0" @change="addValue($event)" class="form-control form-control-sm">
              <option value="">Choose Limbic Value...</option>
              <option v-for="(tag, index) in limbicTags" :value="tag.name" :key="index">{{ tag.name }}</option>
            </select>
            <label class="mt-2">Neue Valu erstellene:</label>
            <input type="text" @change="add('values', $event)" class="form-control form-control-sm" />
          </li>-->
        </ul>
      </div>
    </div>

    <hr>

    <button @click="save" class="btn btn-success">Persona speichern</button>

  </div>
</template>

<script>
// @ is an alias to /src

import LimbicMap from "@/components/LimbicMap";
import FileUpload from "@/components/FileUpload";

export default {
  name: 'PersonasEdit',
  components: {
    FileUpload,
    LimbicMap
  },
  data() {
    return {
      newPersona: {
        id: "",
        type: "",
        image: "",
        name: "",
        title: "",
        age: "",
        motto: "",
        occupation: [],
        story: "",
        family: [],
        limbic: {
          name: "",
          tags: []
        },
        jobIds: [],
        customJobs: [],
        valueProps: [],
        lifestyle: [],
        hobbys: [],
        buyingBehaviour: [],
        products: [],
        meansOfContact: [],
        gains: [],
        pains: [],
        consideration: [],
        notes: []
      }
    }
  },
  computed: {
    jobs() {
      return this.project.jobs.filter(job => {
        return this.newPersona.jobIds.find(jobId => job.id === jobId) === undefined;
      });
    },
    gains() {
      return this.project.canvas
          .reduce((acc, canva) => acc.concat(canva.gains), [])
          .filter(gain => {
            return this.newPersona.gains.find(g => g === gain) === undefined;
          });
    },
    pains() {
      return this.project.canvas
          .reduce((acc, canva) => acc.concat(canva.pains), [])
          .filter(pain => {
            return this.newPersona.pains.find(p => p === pain) === undefined;
          });
    },
    products() {
      return this.project.canvas
          .reduce((acc, canva) => acc.concat(canva.products), [])
          .filter(product => {
            return this.newPersona.products.find(p => p === product) === undefined;
          });
    },
    valueProps() {
      return this.project.canvas
          .reduce((acc, canva) => acc.concat(canva.valueProps), [])
          .filter(valueProp => {
            return this.newPersona.valueProps.find(v => v === valueProp) === undefined;
          });
    },
    limbicTags() {
      return this.$store.state.limbicMap.tags.filter(tag => {
        return this.newPersona.limbic.tags.find(t => t.name === tag.name) === undefined;
      });
    },
    connectedJobs() {
      return this.newPersona.jobIds.map(jobId => {
        return this.project.jobs.find(j => j.id === jobId);
      });
    }
  },
  watch: {
    newPersona(newVal, oldVal) {
      if (oldVal.name !== newVal.name) {
        this.newPersona.limbic.name = newVal.name;
      }
    }
  },
  methods: {
    filesSaved($event) {
      let files = JSON.parse(JSON.stringify($event));
      this.$store.dispatch('upload/uploadFiles', { files, path: "persona-img/" + this.newPersona.id })
          .then(res => {
            console.debug(res);
            this.newPersona.image = res.files[0];
            this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
          });
    },
    add(prop, $event) {
      let newVal = $event.target.value;
      if (newVal === "") return;

      let temp = this.newPersona[prop];
      temp.push(newVal);
      this.$set(this.newPersona, prop, temp);

      $event.target.value = "";

      let pIndex = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(pIndex, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
    },
    remove(prop, index) {
      let temp = this.newPersona[prop];
      temp.splice(index, 1);
      this.$set(this.newPersona, prop, temp);

      let pIndex = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(pIndex, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
    },
    addJob($event) {
      let temp = this.newPersona.jobIds;
      temp.push($event.target.value);
      this.$set(this.newPersona, 'jobIds', temp);

      let pIndex = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(pIndex, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
    },
    addValue($event) {
      let temp = this.newPersona.limbic.tags;
      let newVal = this.limbicTags.find(t => t.name === $event.target.value);
      temp.push(newVal);
      this.$set(this.newPersona.limbic, 'tags', temp);

      let pIndex = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(pIndex, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
    },
    removeValue(index) {
      this.newPersona.limbic.tags.splice(index, 1);

      let pIndex = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(pIndex, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas});
    },
    save() {
      let index = this.project.personas.findIndex(p => p.id === this.newPersona.id);
      this.project.personas.splice(index, 1, this.newPersona);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas})
          .then(res => {
            console.debug(res);
            this.$router.push("/personas");
          });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newPersona = this.project.personas.find(p => p.id === this.$route.params.id);
    } else {
      this.newPersona.id = this.generateId();
      this.project.personas.push(this.newPersona);
    }
  }
}
</script>

<style lang="scss" scoped>
  li {
    position: relative;
    width: calc(100% - 1.5rem);

    &.list-item-add {
      margin-top: 1rem;
      width: 100%;
    }
    i.fa-minus-circle {
      position: absolute;
      right: -1.5rem;
      top: .3rem;
      cursor: pointer;
    }
    &::after {
      content: "";
      display: table;
      clear: bottom;
    }
  }
</style>